<template>
  <div
    class="btn-like pointer"
    :class="{ 'liked': hasLiked }"
    @click="toggleLike"
  >
    <vsvg-icon
      v-if="!hasLiked"
      data="@assetsIcon/brand/like-icon.svg"
      scale="1"
      original
    />
    <vsvg-icon
      v-else
      data="@assetsIcon/brand/liked-icon.svg"
      scale="1"
      original
    />
  </div>
</template>

<script>
import feedApi from '@/modules/feed/services/feed.api'

export default {
  name: 'LikeButton',

  props: {
    hasLiked: {
      type: Boolean,
      default: false,
    },
    likeableType: {
      type: String,
      required: true,
    },
    likeableId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    toggleLike() {
      if (this.loading) {
        return
      }

      if (!this.$store.state.isLogged) {
        this.$router.push({ name: 'auth-login' })
      }

      this.loading = true
      feedApi[this.hasLiked ? 'unlike' : 'like']({
        "module": this.likeableType,
        "entity": this.likeableType,
        "id": `${this.likeableId}`
      }).then(response => {
        this.loading = false
        this.$emit('on-liked-toggled', response.data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-like {
  margin-left: auto;
}
</style>
